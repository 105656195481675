.App {
  text-align: center;
  width:600px;
  margin:auto;
}

p {
  text-align: left;
}

a {
  color:white;
}
